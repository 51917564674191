
// Utilities
import { mapGetters, mapMutations } from 'vuex'
import { ref } from 'vue'

export default {
  data: () => ({
    notifications: [{ href: '/profile', text: 'Lengkapi profile anda' }],
    // title: null,
    responsive: true,
    disableNotification: true,
    sidebarOverlay: false,
    toggleSidebar: false,
    windowWidth: 1024,
  }),
  computed: {
    ...mapGetters(['isAuthenticated', 'loggedInUser']),
  },
  // watch: {
  //   $route(val) {
  //     this.title =
  //       val.name === 'index'
  //         ? '- dashboard'
  //         : '- ' + val.name.split('-').join(' ')
  //   },
  // },
  mounted() {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
    this.windowWidth = ref(window.innerWidth)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },
  methods: {
    ...mapMutations('app', ['setDrawer', 'toggleDrawer']),
    onClick() {
      this.windowWidth = ref(window.innerWidth)

      if (this.windowWidth < 992) {
        this.sidebarOverlay = true
        if (this.toggleSidebar) {
          this.sidebarOverlay = false
          document.body.classList.remove('sidebar-open')
          document.body.classList.add('sidebar-closed', 'sidebar-collapse')
          this.toggleSidebar = false
        } else {
          document.body.classList.remove('sidebar-closed', 'sidebar-collapse')
          document.body.classList.add('sidebar-open')
          this.toggleSidebar = true
        }
        return false
      } else {
        document.body.classList.remove(
          'sidebar-open',
          'sidebar-closed',
          'sidebar-collapse'
        )
      }

      if (this.toggleSidebar) {
        document.body.classList.remove('sidebar-collapse')
        this.toggleSidebar = false
      } else {
        document.body.classList.add('sidebar-collapse')
        this.toggleSidebar = true
      }
    },
    onResponsiveInverted() {
      this.responsive = window.innerWidth < 991
    },
    logout() {
      this.$swal
        .fire({
          title: 'Kamu yakin akan keluar ?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak',
        })
        .then((result) => {
          if (result.value) {
            this.$axios
              .post('/api/logout')
              .then((response) => {
                this.$auth.logout()
                this.$swal.fire({
                  icon: 'success',
                  title: 'Anda telah berhasil keluar',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.$router.push({ path: '/login' })
              })
              .catch((res) => {
                this.data = null
                this.$auth.logout()
                this.$swal.fire({
                  icon: 'warning',
                  title: 'Sesi anda telah berakhir, silahkan masuk kembali',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.$router.push({ path: '/login' })
              })
          }
        })
    },
  },
  head: {
    bodyAttrs: {
      class: 'layout-fixed',
    },
  },
}
