
export default {
  name: 'MaterialCardTable',
  inheritAttrs: false,
  props: {
    url: {
      type: String,
      default: '',
    },
    showjumlahbukubesar: {
      type: Boolean,
      default: false,
    },
    showjumlahneraca: {
      type: Boolean,
      default: false,
    },
    activetextbutton: {
      type: String,
      default: null,
    },
    showcreatewithidbutton: {
      type: Boolean,
      default: false,
    },
    urlcreate: {
      type: String,
      default: '/',
    },
    viewqr: {
      type: Boolean,
      default: false,
    },
    viewqrurl: {
      type: String,
      default: '/',
    },
    urlexport: {
      type: String,
      default: '/',
    },
    urlgenerate: {
      type: String,
      default: '/',
    },
    urlwagenerate: {
      type: String,
      default: '/',
    },
    urlupload: {
      type: String,
      default: '/',
    },
    urlimport: {
      type: String,
      default: '/',
    },
    urlrekening: {
      type: String,
      default: '/',
    },
    urlbackbutton: {
      type: String,
      default: '/',
    },
    urlgeneraterekening: {
      type: String,
      default: '/',
    },
    urlsubdata: {
      type: String,
      default: '/',
    },
    color: {
      type: String,
      default: 'secondary',
    },
    title: {
      type: String,
      default: undefined,
    },
    customtitleleft: {
      type: String,
      default: undefined,
    },
    customtitleright: {
      type: String,
      default: undefined,
    },
    text: {
      type: String,
      default: undefined,
    },
    fields: {
      type: Array,
      default: null,
    },
    fieldsdetail: {
      type: Array,
      default: null,
    },
    subdatatext: {
      type: String,
      default: null,
    },
    showprint: {
      type: Boolean,
      default: false,
    },
    showsubdata: {
      type: Boolean,
      default: false,
    },
    showaction: {
      type: Boolean,
      default: false,
    },
    showcreatebutton: {
      type: Boolean,
      default: false,
    },
    showeditbutton: {
      type: Boolean,
      default: false,
    },
    showdeletebutton: {
      type: Boolean,
      default: false,
    },
    showrekeningbutton: {
      type: Boolean,
      default: false,
    },
    showgeneraterekeningbutton: {
      type: Boolean,
      default: false,
    },
    showfilterbutton: {
      type: Boolean,
      default: false,
    },
    showbackbutton: {
      type: Boolean,
      default: false,
    },
    showactivebutton: {
      type: Boolean,
      default: false,
    },
    isfiltered: {
      type: Boolean,
      default: false,
    },
    isuploaded: {
      type: Boolean,
      default: false,
    },
    isexported: {
      type: Boolean,
      default: false,
    },
    showgeneratecode: {
      type: Boolean,
      default: false,
    },
    showwageneratecode: {
      type: Boolean,
      default: false,
    },
    showpagination: {
      type: Boolean,
      default: false,
    },
    showperpage: {
      type: Boolean,
      default: false,
    },
    perpage: {
      type: Number,
      default: 10,
    },
    pageoptions: {
      type: Array,
      default: null,
    },
    currentpage: {
      type: Number,
      default: 1,
    },
    filters: {
      type: Boolean,
      default: false,
    },
    filterdatas: {
      type: Object,
      default: null,
    },
    variablefilter: {
      type: String,
      default: null,
    },
    exportfilename: {
      type: String,
      default: null,
    },
    topheader: {
      type: Boolean,
      default: false,
    },
    dataTopHeader: {
      type: Array,
      default: null,
    },
    showsppcard: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      isBusy: false,
      search: '',
      items: [],
      total_debit: 0,
      total_kredit: 0,
      saldo_awal: 0,
      saldo_akhir: 0,
      total_saldo_awal: 0,
      total_saldo_akhir: 0,
      totalData: 0,
      firstLoad: false,
      currentPage: 1,
      currentLimit: 10,
    }
  },
  computed: {
    hasOffset() {
      return this.$slots.header || this.$slots.offset || this.title || this.text
    },
    styles() {
      if (!this.hasOffset) return null
      return {
        marginBottom: `${this.offset}px`,
        marginTop: `${this.offset * 2}px`,
      }
    },
  },
  mounted() {
    this.currentPage = this.currentpage
    this.currentLimit = this.perpage
    this.getData()
  },
  methods: {
    showModol() {
      alert('tes')
    },
    printData() {
      const w = window.open()
      w.document.write(this.$refs.PrintData.$el.innerHTML)
      w.document.close()
      w.setTimeout(function () {
        w.print()
      }, 1000)
    },
    async generateCode() {
      await this.$axios
        .get('api/' + this.urlgenerate, {
          responseType: 'arraybuffer',
        })
        .then((res) => {
          const FILE = window.URL.createObjectURL(
            new Blob([res.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            })
          )
          const docUrl = document.createElement('a')
          docUrl.href = FILE
          docUrl.setAttribute('download', 'kode_aktivasi_wali_santri.xlsx')
          document.body.appendChild(docUrl)
          docUrl.click()
        })
        .catch((res) => {
          this.$swal.fire({
            icon: 'warning',
            title: res.response.data.message,
            showConfirmButton: false,
            timer: 1500,
          })
        })
    },
    async SendWaActivation() {
      await this.$swal
        .fire({
          title:
            'Apakah akan melakukan pengiriman kode aktivasi via Whatsapp ?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak',
        })
        .then((result) => {
          if (result.value) {
            this.$axios
              .post('api/' + this.urlwagenerate)
              .then((res) => {
                this.$swal
                  .fire({
                    icon: 'success',
                    title: 'Kode Aktivasi Berhasil dikirim ke Wali Santri.',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                  .then((res) => {
                    this.getData()
                  })
              })
              .catch((res) => {
                this.$swal.fire({
                  icon: 'warning',
                  title: res.response.data.message,
                  showConfirmButton: false,
                  timer: 1500,
                })
              })
          }
        })
    },
    async generateRekening() {
      await this.$swal
        .fire({
          title: 'Apakah akan melakukan Generate Rekening ?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak',
        })
        .then((result) => {
          if (result.value) {
            this.$axios
              .post('api/' + this.urlgeneraterekening)
              .then((res) => {
                this.$swal
                  .fire({
                    icon: 'success',
                    title: 'Rekening berhasil digenerate.',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                  .then((res) => {
                    this.getData()
                  })
              })
              .catch((res) => {
                this.$swal.fire({
                  icon: 'warning',
                  title: res.response.data.message,
                  showConfirmButton: false,
                  timer: 1500,
                })
              })
          }
        })
    },
    async exportExcel() {
      await this.$axios
        .get('api/' + this.urlexport, {
          responseType: 'arraybuffer',
        })
        .then((res) => {
          const FILE = window.URL.createObjectURL(
            new Blob([res.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            })
          )
          const docUrl = document.createElement('a')
          docUrl.href = FILE
          docUrl.setAttribute('download', this.exportfilename + '.xlsx')
          document.body.appendChild(docUrl)
          docUrl.click()
        })
        .catch((res) => {
          this.$swal.fire({
            icon: 'warning',
            title: res.response.data.message,
            showConfirmButton: false,
            timer: 1500,
          })
        })
    },
    async ChangeStatus(id, isActive) {
      await this.$swal
        .fire({
          title: (isActive ? 'non-aktifkan' : 'aktifkan') + ' data ini ?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak',
        })
        .then((result) => {
          if (result.value) {
            this.$axios
              .post('api/' + this.url + '/change-status/' + id, {
                is_active: isActive,
              })
              .then((res) => {
                this.$swal
                  .fire({
                    icon: 'success',
                    title:
                      'Data berhasil di ' +
                      (isActive ? 'non-aktifkan' : 'aktifkan'),
                    showConfirmButton: false,
                    timer: 1500,
                  })
                  .then((res) => {
                    this.getData()
                  })
              })
              .catch((res) => {
                this.$swal.fire({
                  icon: 'warning',
                  title: res.response.data.message,
                  showConfirmButton: false,
                  timer: 1500,
                })
              })
          }
        })
    },
    async ChangeStatusBooking(id, isActive) {
      await this.$swal
        .fire({
          title:
            (isActive ? 'non-aktifkan' : 'Implementasikan') + ' lembaga ini ?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak',
        })
        .then((result) => {
          if (result.value) {
            this.$axios
              .post('api/' + this.url + '/change-status/' + id, {
                is_active: isActive,
              })
              .then((res) => {
                this.$swal
                  .fire({
                    icon: 'success',
                    title:
                      'Data berhasil di ' +
                      (isActive ? 'non-aktifkan' : 'implementasikan'),
                    showConfirmButton: false,
                    timer: 1500,
                  })
                  .then((res) => {
                    this.getData()
                  })
              })
              .catch((res) => {
                this.$swal.fire({
                  icon: 'warning',
                  title: res.response.data.message,
                  showConfirmButton: false,
                  timer: 1500,
                })
              })
          }
        })
    },
    searchData() {
      this.getData(this.search)
    },
    async getData() {
      let query = '?'
      if (this.filterdatas !== null) {
        if (this.filterdatas.q !== undefined) {
          if (query !== '') {
            query += '&'
          }
          query += 'q=' + this.filterdatas.q
        }
        if (this.filterdatas.customer_id !== undefined) {
          if (query !== '') {
            query += '&'
          }
          query += 'customer_id=' + this.filterdatas.customer_id
        }
        if (this.filterdatas.d_k !== undefined) {
          if (query !== '') {
            query += '&'
          }
          query += 'd_k=' + this.filterdatas.d_k
        }
        if (this.filterdatas.nominal_terkecil !== undefined) {
          if (query !== '') {
            query += '&'
          }
          query += 'nominal_terkecil=' + this.filterdatas.nominal_terkecil
        }
        if (this.filterdatas.nominal_terbesar !== undefined) {
          if (query !== '') {
            query += '&'
          }
          query += 'nominal_terbesar=' + this.filterdatas.nominal_terbesar
        }
        if (this.filterdatas.account_id !== undefined) {
          if (query !== '') {
            query += '&'
          }
          query += 'account_id=' + this.filterdatas.account_id
        }
        if (this.filterdatas.dari !== undefined) {
          if (query !== '') {
            query += '&'
          }
          query += 'dari=' + this.filterdatas.dari
        }
        if (this.filterdatas.sampai !== undefined) {
          if (query !== '') {
            query += '&'
          }
          query += 'sampai=' + this.filterdatas.sampai
        }
        if (this.filterdatas.institution_id !== undefined) {
          if (query !== '') {
            query += '&'
          }
          query += 'institution_id=' + this.filterdatas.institution_id
        }
        if (this.filterdatas.paid !== undefined) {
          if (query !== '') {
            query += '&'
          }
          query += 'paid=' + this.filterdatas.paid
        }
        if (this.filterdatas.product !== undefined) {
          if (query !== '') {
            query += '&'
          }
          query += 'product=' + this.filterdatas.product
        }
        if (this.filterdatas.type !== undefined) {
          if (query !== '') {
            query += '&'
          }
          query += 'type=' + this.filterdatas.type
        }

        if (this.filterdatas.nama_santri !== undefined) {
          if (query !== '') {
            query += '&'
          }
          query += 'nama_santri=' + this.filterdatas.nama_santri
        }

        if (this.filterdatas.nik !== undefined) {
          if (query !== '') {
            query += '&'
          }
          query += 'nik=' + this.filterdatas.nik
        }

        if (this.filterdatas.kelas !== undefined) {
          if (query !== '') {
            query += '&'
          }
          query += 'kelas=' + this.filterdatas.kelas
        }

        if (this.filterdatas.province_id !== undefined) {
          if (query !== '') {
            query += '&'
          }
          query += 'province_id=' + this.filterdatas.province_id
        }

        if (this.filterdatas.city_id !== undefined) {
          if (query !== '') {
            query += '&'
          }
          query += 'city_id=' + this.filterdatas.city_id
        }

        if (this.filterdatas.district_id !== undefined) {
          if (query !== '') {
            query += '&'
          }
          query += 'district_id=' + this.filterdatas.district_id
        }

        if (this.filterdatas.sub_district_id !== undefined) {
          if (query !== '') {
            query += '&'
          }
          query += 'sub_district_id=' + this.filterdatas.sub_district_id
        }

        if (this.filterdatas.province !== undefined) {
          if (query !== '') {
            query += '&'
          }
          query += 'province=' + this.filterdatas.province
        }

        if (this.filterdatas.city !== undefined) {
          if (query !== '') {
            query += '&'
          }
          query += 'city=' + this.filterdatas.city
        }

        if (this.filterdatas.district !== undefined) {
          if (query !== '') {
            query += '&'
          }
          query += 'district=' + this.filterdatas.district
        }

        if (this.filterdatas.sub_district !== undefined) {
          if (query !== '') {
            query += '&'
          }
          query += 'sub_district=' + this.filterdatas.sub_district
        }

        if (this.filterdatas.name !== undefined) {
          if (query !== '') {
            query += '&'
          }
          query += 'name=' + this.filterdatas.name
        }

        if (this.filterdatas.phone !== undefined) {
          if (query !== '') {
            query += '&'
          }
          query += 'phone=' + this.filterdatas.phone
        }

        if (this.filterdatas.email !== undefined) {
          if (query !== '') {
            query += '&'
          }
          query += 'email=' + this.filterdatas.email
        }

        if (this.filterdatas.tahun_ajaran_id !== undefined) {
          if (query !== '') {
            query += '&'
          }
          query += 'tahun_ajaran_id=' + this.filterdatas.tahun_ajaran_id
        }

        if (this.filterdatas.page !== undefined) {
          if (query !== '') {
            query += '&'
          }
          query += 'page=' + this.currentPage
        }

        if (this.filterdatas.limit !== undefined) {
          if (query !== '') {
            query += '&'
          }
          query += 'limit=' + this.currentLimit
        }
      }

      await this.$axios
        .get('api/' + this.url + (query !== '' ? query : ''))
        .then((res) => {
          if (res.data.total_data > 0 && !this.firstLoad) {
            this.firstLoad = true
            this.totalData = res.data.total_data
          }
          if (Array.isArray(res.data.data) && res.data.data != null) {
            this.items = res.data.data
          } else if (!Array.isArray(res.data.data) && res.data.data != null) {
            this.items = [res.data.data]
          } else {
            this.items = []
          }

          if (this.items !== null) {
            if (this.showjumlahbukubesar) {
              this.total_debit = res.data.total_debit
              this.total_kredit = res.data.total_kredit
              this.saldo_awal = res.data.saldo_awal
              this.saldo_akhir = res.data.saldo_akhir
            }
            if (this.showjumlahneraca) {
              this.total_debit = res.data.total_debit
              this.total_kredit = res.data.total_kredit
              this.total_saldo_awal = res.data.total_saldo_awal
              this.total_saldo_akhir = res.data.total_saldo_akhir
            }
            setTimeout(function () {
              this.isBusy = true
            }, 5000)
          } else {
            this.items = []
          }
        })
      // .catch((res) => {
      //   this.$swal.fire({
      //     icon: 'warning',
      //     title: res.response.data.message,
      //     showConfirmButton: false,
      //     timer: 1500,
      //   })
      // })
    },
    printQr(id) {
      let url = window.location.origin + '/' + this.viewqrurl
      if (id !== 'all') {
        url += '&customer_id=' + id
      }

      console.log('url :', url)

      console.log(url)
      const div = document.getElementById('printerDiv')
      div.innerHTML = '<iframe src="' + url + '"></iframe>'

      setTimeout(function () {
        document.querySelector('#printerDiv > iframe').contentWindow.print()
      }, 5000)
    },
    async deleteData(id) {
      await this.$swal
        .fire({
          title: 'hapus data ini ?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak',
        })
        .then((result) => {
          if (result.value) {
            this.$axios.delete('api/' + this.url + '/' + id).then((res) => {
              this.$swal
                .fire({
                  icon: 'success',
                  title: 'Data berhasil di hapus',
                  showConfirmButton: false,
                  timer: 1500,
                })
                .then((res) => {
                  this.getData()
                })
            })
          }
        })
    },
  },
}
